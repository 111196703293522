<template>
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
          <el-form-item label="">
            <el-select style="width: 150px;" v-model="filters.invoiceStatus" placeholder="发票匹配状态" filterable clearable>
              <el-option
                  v-for="item in billToMatch"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select style="width: 150px;" v-model="filters.verificationStatus" placeholder="收款匹配状态" filterable
                       clearable>
              <el-option
                  v-for="item in invoiceToMatch"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select style="width: 150px;" v-model="filters.applyStatus" placeholder="申请状态" filterable
                       clearable>
              <el-option
                  v-for="item in applyStatusArr"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-date-picker @change="changePicker" v-model="pickerData" type="daterange" range-separator="至"
                            start-placeholder="开始" value-format="yyyy-MM-dd" end-placeholder="结束"
                            style="width: 200px;">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="filters.invoiceQuery" @input="(e) => (filters.invoiceQuery = utils.validForbid(e))"
                      clearable placeholder="请输入发票名称"></el-input>
          </el-form-item>
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!--  列表-->
    <el-table
        :data="objectDataList"
        border
        style="width: 100%"
        v-loading="listLoading"
        ref="multipleTable"
        @current-change='currentChange'
        :highlight-current-row='true'
        :cell-style="rowClass"
    >
      <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
      <el-table-column prop="issuerType" label="开票类型" width='100' align="center" show-overflow-tooltip>
        <template slot-scope='scope'>
          <el-tag v-if="scope.row.issuerType === 0">个人</el-tag>
          <el-tag v-if="scope.row.issuerType === 1" type="success">企业</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="invoiceTpye" label="发票类型" width='110' align="center" show-overflow-tooltip>
        <template slot-scope='scope'>
          <el-tag v-if="scope.row.invoiceTpye == 0" type="success">全电专票</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="invoiceMoney" label="开票金额" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="invoiceTitle" label="发票抬头" align="center" show-overflow-tooltip>
<!--        <template #default="scope">-->
<!--          <el-button type="text" @click="invoiceDetails(scope.row)" >-->
<!--            {{ scope.row.invoiceTitle }}</el-button>-->
<!--        </template>-->
      </el-table-column>
      <el-table-column prop="invoiceNo" label="发票号" align="center" show-overflow-tooltip>
<!--        <template #default="scope">-->
<!--          <el-button @click="viewsEntyTemplate(scope.row)" type="text">{{scope.row.invoiceNo}}</el-button>-->
<!--        </template>-->
      </el-table-column>
      <el-table-column prop="invoiceFax" label="购方税号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="invoiceBankAccount" label="购买方名称" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="externalPersonPhone" label="手机号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="externalPersonEmail" label="邮箱" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="openTime" label="开票时间" width="120" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="verificationStatus" label="收款匹配状态" width='120' align="center" show-overflow-tooltip>
        <template slot-scope='scope'>
          <el-tag v-if="scope.row.verificationStatus === 0" type="danger">未匹配</el-tag>
          <el-tag v-if="scope.row.verificationStatus === 1" type="success">部分匹配</el-tag>
          <el-tag v-if="scope.row.verificationStatus === 2">全部匹配</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="invoiceStatus" label="发票申请状态" width='120' align="center" show-overflow-tooltip>
        <template slot-scope='scope'>
          <el-tag v-if="scope.row.invoiceStatus === 0" type="success">未开票</el-tag>
          <el-tag v-if="scope.row.invoiceStatus === 1" type="danger">开票失败</el-tag>
          <el-tag v-if="scope.row.invoiceStatus === 2" >已开票</el-tag>
          <el-tag v-if="scope.row.invoiceStatus === 3" type="info">已作废</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="applyStatus" label="申请状态" width='120' align="center" show-overflow-tooltip>
        <template slot-scope='scope'>
          <el-tag v-if="scope.row.applyStatus === 0" type="success">申请中</el-tag>
          <el-tag v-if="scope.row.applyStatus === 1" type="danger">申请失败</el-tag>
          <el-tag v-if="scope.row.applyStatus === 2" >申请成功</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.pageIndex"
          :page-sizes="pages.pageArr"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataCount">
      </el-pagination>
    </el-col>
    <el-dialog title="发票详情列表" :visible.sync="invoiceDialog" v-model="invoiceDialog"
               :close-on-click-modal="false" width='80%'>
      <el-table
          :data="invoiceDetailList"
          border
          style="width: 100%"
          v-loading="listLoading"
          ref="multipleTable"
          :highlight-current-row='true'
          :cell-style="rowClass"
      >
        <!--            @current-change='currentInvoiceChange'-->
        <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
        <el-table-column prop="projectName" label="项目名称" width='120' align="center" ></el-table-column>
        <el-table-column prop="contractId" label="合同ID" align="center" width='120' show-overflow-tooltip>
<!--          <template slot-scope='scope'>-->
<!--            <el-tag v-if="scope.row.issuerType == 0">个人</el-tag>-->
<!--            <el-tag v-if="scope.row.issuerType == 1" type="success">企业</el-tag>-->
<!--          </template>-->
        </el-table-column>
        <el-table-column prop="belongPeriod" label="所属周期" width='120' align="center" show-overflow-tooltip>
<!--          <template slot-scope='scope'>-->
<!--            <el-tag v-if="scope.row.invoiceTpye == 0" type="success">全电专票</el-tag>-->
<!--          </template>-->
        </el-table-column>
        <el-table-column prop="projectArea" label="图审面积" width='120' align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="cycleAmount" label="应收单账单金额" width='120' align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="financialReceivableReportId" label="关联应收账单ID" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="invoiceAmount" label="金额" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="calculateStartDay" label="账单开始时间" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="calculateEndDay" label="账单截止时间" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="itemDetail" label="明细说明" align="center" show-overflow-tooltip></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="invoiceDialog= false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import util from "../../../util/date";
import { getButtonList } from '@/promissionRouter'
import {
  getInvoiceList,
  getInvoiceDetailList,
} from '@/api/api'

export default {
  components: { Toolbar },
  data () {
    return {
      pickerData: [],
      utils:'',
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [15, 30, 45, 60],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: this.dataCount, //总条数
      },
      dataCount:undefined,
      //发票状态
      billToMatch: [
        { name: '未开票', value: 0 },
        { name: '开票失败', value: 1 },
        { name: '已开票', value: 2 },
        { name: '已作废', value: 3 },
      ],
      //项目状态
      objectToMatch: [
        { name: '未匹配', value: 0 },
        { name: '部分匹配', value: 1 },
        { name: '全部匹配', value: 2 }
      ],
      invoiceToMatch: [
        { name: '未匹配', value: 0 },
        { name: '部分匹配', value: 1 },
        { name: '全部匹配', value: 2 }
      ],
      applyStatusArr:[
        { name: '申请中', value: 0 },
        { name: '申请失败', value: 1 },
        { name: '申请成功', value: 2 }
      ],
      //筛选
      filters: {
        verificationStatus:'',//收款匹配状态
        invoiceName: '',//发票名称
        filingStartDate: '',//开票开始时间
        filingEndDate: '',//开票结束时间
        invoiceQuery: '',//发票名称查询
        invoiceStatus:'',//发票匹配状态
        applyStatus:'',//申请状态
      },
      objectComplete: {},//列表接口数据
      objectDataList: [],//列表数组
      listLoading: false,
      addLoading: false,
      buttonList: [],//按钮事件列表
      needData: undefined,
      dialog: {
        //匹配项目是否弹出
        matchObjectDialog: {
          show: false
        },
        //匹配发票是否弹出
        matchInvoiceDialog: {
          show: false
        },
      },
      objectMatchList: [],//项目匹配
      InvoiceMatchList: [],//发票匹配
      needInvoiceData: {},//发票列表某一行
      needObjectData: {},//项目列表某一行
      addForm: {
        revenuePaymentsId: undefined,
        matchAmount: 0,
        financialReceivableCycleId: undefined,
      },
      paymentName: '',
      revenuePaymentData: undefined,
      openInvoice:false,
      invoiceDialog:false,
      invoiceDetailList:[],
    }
  },
  created () {
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
    this.init();
    this.getDataList()
  },
  methods: {
    //发票详情
    invoiceDetails(val){
      this.getInvoiceList(val.id)
      this.invoiceDialog = true;

    },
    //获取列表数据
    getDataList () {
      let params = {
        // invoiceIds: [],//发票ID组（逗号隔开）
        applyStatus:this.filters.applyStatus,//申请状态
        verificationStatus: this.filters.verificationStatus,//收款匹配状态
        invoiceStatus: this.filters.invoiceStatus,//发票匹配状态
        invoiceTitle: this.filters.invoiceQuery,//发票名称查询
        openEndDate: this.filters.filingEndDate,//开票结束时间
        openStartDate: this.filters.filingStartDate,//开票开始时间
        pageIndex: 1,//页码
        pageSize: 15,//每页条数
      }
      getInvoiceList(params).then(res => {
        let result = res.data;
        if (result.success) {
          const response = result.response
          this.dataCount = response.dataCount
          const data = response.data
          this.objectDataList = data
        }
      })
    },
    // 查询
    getListData () {
      this.pages.pageIndex = 1
      this.getDataList();
    },
    async getInvoiceList(id){
      let params = {
        invoiceId:id,
      }
      const res = await getInvoiceDetailList(params)
      let result = res.data
      if (result.status === 200){
        let response = result.response
        console.log(response,'response')
        this.invoiceDetailList = response
      }
      console.log(result,'result')
    },
    //按钮事件
    callFunction (item) {
      this[item.func].apply(this, item);
    },
    // 点击table某一行
    currentChange (val) {
      this.needData = val
      console.log(val)

    },
    handleSelect (val) {
      this.addForm.revenuePaymentsId = val.id;
      console.log(val, 'val');
    },
    viewsEntyTemplate(){
        this.openInvoice = true;
    },

    // 日期选择
    changePicker (data) {
      if (data !== null) {
        this.filters.filingStartDate = data[0];
        this.filters.filingEndDate = data[1];
      } else {
        this.filters.filingStartDate = undefined;
        this.filters.filingEndDate = undefined;
      }
    },
    // 表格数据分页条数累计
    indexMethod (index) {
      let currentPage = this.pages.pageIndex
      let pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
    rowClass ({ columnIndex }) {
      return 'padding:8px!important;'
    },
    // 分页
    handleCurrentChange (val) {
      console.log(val,'fenye')
      this.pages.pageIndex = val;
      this.getDataList();
    },
    handleSizeChange (val) {
      console.log(val,'每页条数')
      this.pages.pageSize = val;
      this.getDataList();
    },
    async init () {
      this.getDataList()
    }
  }
}
</script>

<style scoped>

</style>
